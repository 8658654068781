.mat-dialog-container-no-space {
  .mat-dialog-container {
    padding: 0;
  }
}

.mat-dialog-content.mat-dialog-content-height-auto {
  height: auto;
  max-height: initial;
}

.mat-dialog-close {
  right: -8px;
  top: -8px;
}
