.mat-chip.mat-standard-chip .mat-chip-add {
  @extend .mat-chip-remove;
}

.mat-standard-chip .mat-chip-add {
  cursor: pointer;
  margin-left: 8px;
  margin-right: 0;
  border: none;
  padding: 0;
  background: none;
  line-height: 0;
}

.mat-form-field .mat-chip-list-wrapper {
  min-height: 40px;
}

.mat-chip {
  max-width: 100%;
}
