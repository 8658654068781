.mat-form-field {
  width: 100%;

  &.no-space .mat-form-field-wrapper {
    margin: 0;
    padding-bottom: 0;

    & .mat-form-field-underline {
      bottom: 0;
    }
  }

  & [type="time"] {
    background: transparent url('../assets/icons/timer.svg') 100% 100% no-repeat !important;
    border-width: 0;
    box-shadow: none;
    height: 22px;
    margin-top: -8px !important;
    margin-bottom: -4px !important;
    padding-top: 3px !important;
  }

  & [type="time"]::-webkit-inner-spin-button {
    display: none;
  }

  & [type="time"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    width: 24px;
  }

  & [type="time"]:active,
  & [type="time"]:hover,

  & [type="time"]:focus {
    outline: none;
    box-shadow: none;
  }
}

.mat-form-field-infix {
  width: 100%;
}

.mat-form-field-appearance-outline {
  &.no-space .mat-form-field-wrapper {
    margin: 0;
    padding-bottom: 0;
  }
}

input.mat-input-element {
  text-overflow: ellipsis !important;
}
