$success-color: #1D623C;
$warn-color: #d9b100;
$orange-color: #f87f14;
$primary-color: #cc0000;
$warn-color: #c71a2f;

$red-w80-color: #e7a0a9;
$yellow-w80-color: #f0e099;
$lime-w80-color: #d3dbaa;
$green-w80-color: #ade3c9;
$grey-w80-color: #d3d3d3;
