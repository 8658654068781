@use '@angular/material' as mat;
@import './variables';

$accent-color: mat.get-color-from-palette(mat.$gray-palette, A700);

ag-grid-angular.ag-theme-material {
  
  .ag-virtual-list-container {
    overflow: visible !important;
  }

  & .ag-cell-range-selected-1 {
    border-left: 1px solid var(--ag-range-selection-border-color, $warn-color) !important;

    & + .ag-cell-range-selected-1 {
      border-left: none !important;
    }
  }

  & .ag-cell-inline-editing {
    height: 48px;
    margin-left: 1px;
    padding: 0 0 0 12px;

    & .mat-form-field-appearance-outline {
      & .mat-form-field-infix {
        padding: 8px 0 16px 0;
      }

      & .mat-form-field-outline {
        color: transparent !important;
      }

      &.mat-focused .mat-form-field-outline-thick {
        color: transparent !important;
      }

      & .mat-form-field-prefix,
      & .mat-form-field-suffix {
        top: 8px;
      }

      & .mat-form-field-prefix .mat-icon,
      & .mat-form-field-suffix .mat-icon {
        font-size: 24px;
      }
    }
  }
}
