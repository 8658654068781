@use 'sass:map';
@use '@angular/material' as mat;
@import './variables';

@mixin color($apx-theme) {
  $color-config: mat.get-color-config($apx-theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');
  $foreground: map.get($color-config, 'foreground');
  $background: map.get($color-config, 'background');

  a {
    color: mat.get-color-from-palette($foreground, text);
  }

  .permitted-link {
    color: #0a8fa1;

    &:hover {
      text-decoration: underline;
    }

    &.disabled {
      @extend .text-muted;
      pointer-events: none;
      cursor: default;
    }
  }

  .permitted-color {
    color: #0a8fa1;
  }

  .border-left-primary {
    border-left: 4px solid mat.get-color-from-palette($primary-palette);
  }

  .order-status {
    &-pending {
      color: $orange-color;
    }

    &-out-delivery {
      color: #43494e;
    }

    &-pending-approval {
      color: $orange-color;
    }

    &-completed {
      color: $success-color;
    }

    &-cancelled {
      color: mat.get-color-from-palette($warn-palette);
    }
  }

  .bg-order-status {
    &-out-delivery {
      background-color: rgba($yellow-w80-color, 0.5) !important;
    }

    &-completed {
      background-color: rgba($grey-w80-color, 0.5) !important;
    }

    &-cancelled {
      background-color: rgba($red-w80-color, 0.5) !important;
    }

    &-pending {
      background-color: rgba($green-w80-color, 0.5) !important;
    }
  }

  .bg-accent {
    background: mat.get-color-from-palette($accent-palette);
    color: mat.get-color-from-palette($accent-palette, default-contrast);
  }

  .bg-warn {
    background: mat.get-color-from-palette($warn-palette);
    color: mat.get-color-from-palette($warn-palette, default-contrast);
  }

  .text-primary {
    color: mat.get-color-from-palette($primary-palette);
  }

  .text-success {
    color: $success-color;
  }

  .text-warn {
    color: mat.get-color-from-palette($warn-palette);
  }

  .text-muted {
    color: mat.get-color-from-palette($accent-palette, 500);
  }

  .text-orange {
    color: $orange-color;
  }

  .badge-text-primary .mat-badge-content {
    color: mat.get-color-from-palette($primary-palette);
  }

  .active-nav-list-item {
    background: mat.get-color-from-palette($background, 'hover');
  }

  .active-nav-dark-list-item {
    background: mat.get-color-from-palette($background, 'focused-button');
  }

  .action-cell-button {
    color: mat.get-color-from-palette(mat.$grey-palette, 600);

    :hover {
      color: mat.get-color-from-palette($primary-palette);
    }
  }

  .mat-paginator {
    background: transparent;
    margin-top: auto;
  }

  .hidden-block {
    background: white;
  }

  .mat-row.is-selected, .mat-row.is-selected .hidden-block {
    background: mat.get-color-from-palette(mat.$grey-palette, 100);
  }
}

@mixin typography($apx-theme) {

}

@mixin theme($apx-theme) {
  $color-config: mat.get-color-config($apx-theme);
  @if $color-config != null {
    @include color($apx-theme);
  }

  $typography-config: mat.get-typography-config($apx-theme);
  @if $typography-config != null {
    @include typography($apx-theme);
  }
}
